import { WrenchIcon } from '@heroicons/react/16/solid';
import { DateTime } from 'luxon';

export type ImportantMessage = {
  id: string;
  title: string;
  icon: React.ElementType;
  showUntil: DateTime;
  showFrom: DateTime;
  message: string;
};

export const IMPORTANT_MESSAGES: ImportantMessage[] = [
  {
    id: '1',
    title: 'Planerat servicefönster söndag 23/3',
    icon: WrenchIcon,
    showUntil: DateTime.fromISO('2025-03-24T00:00:00Z'),
    showFrom: DateTime.fromISO('2025-03-18T12:00:00Z'),
    message:
      'För att uppgradera vår prestanda planeras en uppdatering som innebär att våra tjänster kommer vara nere på söndag kväll. Våra tjänster kommer vara tillbaka igen på måndag morgon.',
  },
];
