'use client';

import { XMarkIcon } from '@heroicons/react/16/solid';
import { AnimatePresence, motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { Button } from '../ui/button';
import { IMPORTANT_MESSAGES, type ImportantMessage } from './constants';

export default function ImportantMessages() {
  const [importantMessages, setImportantMessages] = useState<
    ImportantMessage[]
  >([]);

  const [dismissedMessages, setDismissedMessages] = useLocalStorage<string[]>(
    'dismissed-important-messages',
    []
  );

  useEffect(() => {
    // Get all messages that are currently active
    const activeMessages = IMPORTANT_MESSAGES.filter((message) => {
      return (
        DateTime.now() >= message.showFrom &&
        DateTime.now() <= message.showUntil
      );
    });

    // Remove dismissed messages from active messages
    const filteredMessages = activeMessages.filter(
      (message) => !dismissedMessages.includes(message.id)
    );

    setImportantMessages(filteredMessages);
  }, [dismissedMessages]);

  if (!importantMessages.length) return null;

  return (
    <div className='fixed inset-x-0 top-0 z-50 flex max-h-screen w-full flex-col items-center gap-2 overflow-y-auto'>
      <AnimatePresence mode='popLayout'>
        {importantMessages.map((message) => (
          <motion.div
            key={message.id}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className='mx-4 mt-4 w-full max-w-[800px] rounded-lg bg-background p-4 shadow-md ring-2 ring-border'
          >
            <div className='flex items-center justify-between gap-2'>
              <div className='flex items-center gap-2'>
                <message.icon className='size-6' />
                <h3 className='text-lg font-bold'>{message.title}</h3>
              </div>

              <Button
                variant='outline'
                size='icon'
                onClick={() => {
                  setDismissedMessages([...dismissedMessages, message.id]);
                }}
              >
                <XMarkIcon className='size-4' />
              </Button>
            </div>
            <p className='my-2 text-sm'>{message.message}</p>

            <div className='mt-6 flex items-center justify-end gap-2'>
              <Button
                onClick={() => {
                  setDismissedMessages([...dismissedMessages, message.id]);
                }}
                size='sm'
              >
                OK, dölj meddelande
              </Button>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}
